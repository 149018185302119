<template>
    <div style="margin:-12px;width:100vw;height:100%;" class="d-flex align-center justify-center">
        <background page="notFound" />
        <v-card class="rounded-xl shadow ma-5">
            <v-card-text class="text-center" style="width:100%;">
                <v-icon color="secondary" size="150">mdi-alert-circle-outline</v-icon>
                <div class="text-h5 ma-2">No encontrado</div>
                <div class="body-2">La página o producto no existe</div>

                <v-btn block depressed rounded class="mt-5" color="accent" to="/">Volver al inicio</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Background from '@/components/Background'
export default {
    components: { Background }
}
</script>

<style lang="scss" scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
</style>